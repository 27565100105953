import React, { useEffect, useState, useRef, } from 'react';
import { Modal, Button,  } from 'react-bootstrap';
import CardWechat from './login_wechat.jsx'
import { LoginGoogle, LoginWechatMobile } from './login_auth_url.jsx';
import './login.css'

function LoginModal({ isMobile, onModalClose }) {
    const [show, setShow] = useState(true);
    const [loginType, setLoginType] = useState(null)
    
    const socketRef = useRef(null);

    const handleClose = () => {
        setShow(false);
        // 在这里调用父组件传递的处理函数
        // 关闭 WebSocket 连接
        if (socketRef.current) {
            socketRef.current.disconnect();
        }

        // 定义一个空的对象
        let loginInfo = {};
        onModalClose(loginInfo);
    };

    const handleButtonClick = (event) => {
        setLoginType(event.target.id)
    };

    useEffect(() => {
        if(loginType) {
            if (loginType=== "google") {
                setLoginType("wechat")
            } else if (loginType=== "wechat") {
                setLoginType("google")
            };
            
            handleClose();
        };
    }, [loginType])


    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header className="justify-content-center bg-light">
                <div className="profile modal-gx-n">
                    <div className="profile-img text-primary rounded-top-xl"></div>
                    <div className="profile-body">
                        <div className="avatar avatar-xl">
                            <span className="avatar-text avatar-text-user">
                                <i className="bi bi-person icon-1-5x"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="justify-content-center bg-light">
                <div className="container py-8">
                    <CardWechat isMobile={isMobile} onModalClose={onModalClose}></CardWechat>
                </div>
                <hr className="my-3"></hr>
                <div className="container d-flex justify-content-center py-8">
                    <Button
                        variant="outline-success"
                        // className={`py-2 mb-2 m-2 btn-outline-secondary rounded-3 ${(loginType === "wechat" && !isMobile) ? "active" : ""}`}
                        className={`py-2 mb-2 btn-outline-secondary rounded-3 active`}
                        // onClick={handleButtonClick}
                        id="wechat"
                    >
                        <i className="bi bi-wechat"></i>    Login with Wechat
                    </Button>
                    <div className="text-body-secondary d-flex justify-content-center align-items-center hr-or-container">
                        <hr className="hr-or" />
                        <span style={{ whiteSpace: 'nowrap' }}>or</span>
                        <hr className="hr-or" />
                    </div>
                    <Button
                        variant="outline-success"
                        className={`py-2 mb-2 m-2 btn-outline-secondary rounded-3 ${loginType === "google" ? "active" : ""}`}
                        onClick={handleButtonClick}
                        id="google"
                    >
                        <i className="bi bi-google"></i>    Login with Google
                    </Button>
                    { loginType === "google" && <LoginGoogle /> }
                    { (loginType === "wechat" && isMobile) && <LoginWechatMobile /> }
                </div>
            </Modal.Body>
        </Modal>
    );        
}

export default LoginModal;
