import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import CodeBlock from './code_block.jsx';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import 'katex/dist/katex.min.css'; // 引入 KaTeX 的样式

const MarkdownRender = ({ children }) => {
    // console.log(6, children);
    const components = {
        code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');

            return !inline && match ? (
                // <CodeBlock language={match[1]} value={String(children).replace(/\n$/, '')} {...props} />
                <MathJax><CodeBlock language={match[1]} value={String(children)} {...props} /></MathJax>
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        }
    };

    const formattedResponse = children
        .replace(/\\\[/g, '$$')
        .replace(/\\\]/g, '$$')
        .replace(/\\\(/g, '$')
        .replace(/\\\)/g, '$');

    return (
        <MathJaxContext>
            <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkMath]} // 添加 remark 插件
                rehypePlugins={[rehypeKatex]} // 添加 rehype 插件
                components={components}
                breaks={true}>
                    {formattedResponse}
                </ReactMarkdown>
        </MathJaxContext>
    );

    // console.log(27, children);
    // return (
    //     <MathJaxContext>
    //         <ReactMarkdown
    //             remarkPlugins={[remarkGfm, remarkMath]} // 添加 remark 插件
    //             rehypePlugins={[rehypeKatex]} // 添加 rehype 插件
    //             components={{
    //                 code({ node, className, children, ...props }) {
    //                     const match = /language-(\w+)/.exec(className || '');
    //                     return match ? (
    //                         <MathJax>{String(children).replace(/\n$/, '')}</MathJax>
    //                     ) : (
    //                         <code className={className} {...props}>
    //                             {children}
    //                         </code>
    //                     );
    //                 }
    //             }}
    //             breaks={true}
    //         >
    //             {formattedResponse}
    //         </ReactMarkdown>
    //     </MathJaxContext>
    // );
};

export default MarkdownRender;