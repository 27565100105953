import React, { useState, useEffect } from "react";
import ChatMainMessage from "./chat_main_message.jsx"
import ChatMainDialog from "./chat_main_dialog.jsx";
import { chatImageMJ, baseUrl_api, chatClassFile, toolMidjourney,toolPhotoMaker, showParam, chatMedia, fileTranslator, } from "./config.jsx";
import FilesContainerSend from "./chat_main_files_container_send.jsx";
import FilesContainerShow from "./chat_main_files_container_show.jsx";
import FilesContainerTranslate from "./chat_main_files_container_translate.jsx";
import ShowMJParam from "./modal_midjourney_param.jsx";
import ShowPMParam from "./modal_photomarker_param.jsx";
import MJImageExpand from "./chat_main_mj_image_expand.jsx";
import SunoExpand from "./chat_main_suno_expand.jsx";
import { toast } from "react-toastify";

function ChatMain ({ uid, chatClass }) {
    const [messageSend, setMessageSend] = useState(""); // 更改状态变量的名称以避免混淆
    const [messageReceive, setMessageReceive] = useState("");
    const [isReceived, setIsReceived] = useState(true);
    const [forceUpdate, setForceUpdate] = useState(0);
    const [showFilesSend, setShowFilesSend] = useState(false);
    const [showFilesShow, setShowFilesShow] = useState(false);
    const [showFilesTranslate, setShowFilesTranslate] = useState(false);
    const [showMJParam, setShowMJParam] = useState(false);
    const [showPMParam, setShowPMParam] = useState(false);
    const [files, setFiles] = useState([]);
    const [fileType, setFileType] = useState(null);
    const [toolButtons, setToolButtons] = useState("");
    const [mjParam, setMJParam] = useState(null);
    const [pmParam, setPMParam] = useState(null);
    const [imageParam, setImageParam] = useState(null);
    const [price, setPrice] = useState(2);
    const [showMJImageEXpand, setShowImageExpand] = useState(false);
    const [showSunoExpand, setShowSunoExpand] = useState(false);
    const [sunoParam, setSunoParam] = useState(null);
    const [attachments, setAttachments] = useState(null); // 用于记录发送消息时候的附近

    // 记录上传文件的 url
    const [fileUrl, setFileUrl] = useState(null);
    
    useEffect (() => {
        setMessageReceive("");
        if(chatClass.className) {
            setMessageReceive("");
            setMessageSend("");
            setFileUrl(null);
            setAttachments(null);
        };

        if(showParam.includes(chatClass.className)) {
            setPrice(5);
        } else {
            setPrice(2);
        };

        if (toolPhotoMaker.includes(chatClass.className)) {
            const param = {
                prompt: "{prompt}",
                active: true,
            };
            setImageParam(param);
        }
    }, [chatClass.className])

    // 根据 className 判断显示哪个 param 页面
    const handleShowParam = () => {
        if (toolMidjourney.includes(chatClass.className)) {
            setShowMJParam(true);
        } else if (toolPhotoMaker.includes(chatClass.className)) {
            setShowPMParam(true);
        };        
    };

    // 显示 MJ 图片扩展组件
    const handleShowExpand = () => {
        if (chatMedia.includes(chatClass.className)) {
            setShowSunoExpand(true);
        } else if (chatImageMJ.includes(chatClass.className)) {
            setShowImageExpand(true);
        };        
    };


    const sendMessageToChat = async (messageObject, trimmedText) => {
        if ( (trimmedText !== "") 
            || (chatClass.className.toLowerCase().startsWith("photomaker")
                && imageParam.prompt
                && imageParam.prompt !==""
                && typeof imageParam.prompt === 'string' 
                && !imageParam.prompt.includes("{prompt}"))) {        
            try {
                if (chatClass.className.toLowerCase().startsWith("photomaker") && trimmedText === "") {
                    trimmedText = imageParam.prompt
                    setMessageSend(trimmedText);
                    setForceUpdate(prev => prev + 1);
                };

                let mjGraphFiles = messageObject.text.files;
                let param;

                switch (chatClass.className.toLowerCase()) {
                    case "midjourney":
                    case "midjourney_graph":
                        param = imageParam && imageParam.active ? imageParam : null;

                        if (param && param.version) {                    
                            for (let file of mjGraphFiles) {
                                if (file.action !== "") {
                                    // 检查 action 值的前缀并根据条件修改 param 的 version 属性
                                    if (param.version.startsWith("--v")) {
                                        param.version = "--v 6.0";
                                    } else if (param.version.startsWith("--niji")) {
                                        param.version = "--niji 6";
                                    };
                                    // 找到第一个不为空的 action 值后退出循环
                                    break;
                                };
                            }
                        };
                        break;
                    
                    case "suno":
                        if(messageObject.text) {
                            param = {
                                ...sunoParam,
                                ...messageObject.text
                            };
                        };
                        
                        break;
                    case "translator":
                        if(messageObject.text) {
                            param = {
                                ...messageObject.text.language
                            };
                        };
                    
                    default:
                        break;
                }
                const userLanguage = navigator.language || navigator.userLanguage;            
                
                // vision 在发送消息的时候显示提交的附件, 其他对话不显示
                if (messageObject.chatType.toLowerCase() === "vision") {
                    setAttachments(messageObject.text.files);
                } else {
                    setAttachments(null);
                };

                // const test = {
                //     "uid": uid,
                //     "message": trimmedText, // 确保发送的是处理过的文本
                //     "model": messageObject.model, // 发送消息对象中的model属性
                //     "classID": chatClass.classID,
                //     "msgType": messageObject.msgType,
                //     "chatType": messageObject.chatType,
                //     "files": messageObject.text.files,
                //     "param": param,
                //     "mkt": userLanguage,
                // };

                // console.log(88, test);
                // console.log(90, userLanguage);
                // // return;
                
                const response = await fetch(baseUrl_api + "/sendmessage", {
                    method: "POST",
                    headers: { "Content-Type": "application/json", },
                    credentials: "include",
                    body: JSON.stringify({
                        "uid": uid,
                        "message": trimmedText, // 确保发送的是处理过的文本
                        "model": messageObject.model, // 发送消息对象中的model属性
                        "classID": chatClass.classID,
                        "msgType": messageObject.msgType,
                        "chatType": messageObject.chatType,
                        "files": messageObject.text.files,
                        "param": param,
                        "mkt": userLanguage,
                    }),
                });
                
                if(response.status) {
                    if (!messageObject.chatType.toLowerCase().includes("chat", "vision")){
                        setIsReceived(true);
                    };
                    // setIsReceived(true);
                    setAttachments(null);
                };

                if (!response.ok) {
                    setAttachments(null);
                    // 如果 HTTP 状态码不是 2xx, 抛出异常
                    throw new Error(`HTTP error! status: ${response.status}`);
                };

                // 返回内容
                // console.warn(141, response.status, messageObject.chatType);                    
                if(response.status === 200) {
                    switch (messageObject.chatType.toLowerCase()) {
                        case "chat":
                        case "vision":
                            // console.warn(119, messageObject.model.toLowerCase());
                            const reader = response.body.getReader();
                            const decoder = new TextDecoder(); // 创建一个 TextDecoder 实例来解码流
                            const messageType = 'text'; // 定义消息类型

                            // 读取数据流
                            const readStream = async () => {
                                let done, value;
                                while (true) { // 修改循环条件
                                    ({ done, value } = await reader.read());
                                    if (done) {                                        
                                        setIsReceived(true);
                                        break;
                                    };
                                    const chunk = decoder.decode(value, { stream: true });
                                    // console.log(127, chunk);
                                    let code=200;
                                    let message="";
                                    let jsonGPT = {};
                                    // 检查数据类型
                                    if (typeof chunk === 'string') {
                                        try {
                                            // 尝试解析为 JSON 对象
                                            let chunkObj = JSON.parse(chunk);
                                            if ("message" in chunkObj) {
                                                chunkObj = chunkObj.message;
                                            };

                                            code = chunkObj['code'];
                                            message = chunkObj['response'];
                                            // console.log(230, chunkObj, code, message);
                                        } catch (error) {
                                            // 解析失败，将其视为其他类型的数据
                                            // console.error(error);
                                            code = 200;
                                        };
                                    } else {
                                        code = 200;
                                    };
                                    
                                    try {
                                        if (code === 3000 ) {
                                            jsonGPT = JSON.stringify({ type: 'warning', message: message });
                                            setMessageReceive(jsonGPT);
                                        } else if (code >= 4000 ) {
                                            jsonGPT = JSON.stringify({ type: 'error', message: message });                                        
                                            setMessageReceive(jsonGPT);
                                        } else {
                                            if (messageObject.model.toLowerCase().endsWith("_online")) {
                                                jsonGPT = JSON.stringify({ type: message.type, message: message.value });
                                                setMessageReceive(jsonGPT)
                                            } else if (messageObject.model.toLowerCase() === "suno") {
                                                setMessageReceive(prev => JSON.stringify({
                                                    type: "media",
                                                    message: prev ? JSON.parse(prev).message + chunk : chunk
                                                }));
                                            } else {
                                                setMessageReceive(prev => JSON.stringify({
                                                    type: "text",
                                                    message: prev ? JSON.parse(prev).message + chunk : chunk
                                                }));
                                            };
                                        };
                                        setMessageSend("");
                                        setForceUpdate(prev => prev + 1);
                                    } catch (error) {
                                        console.error(266, error);
                                    };
                                };

                                // 如果流结束后还有数据没有被解码，确保将其添加到状态中
                                const finalChunk = decoder.decode();
                                if (finalChunk) {
                                    // 创建 JSON 格式字符串并传递给 setMessageReceive
                                    // const jsonMessage = JSON.stringify({ type: messageType, message: finalChunk });
                                    setMessageReceive(prev => JSON.stringify({
                                        type: messageType,
                                        message: prev ? JSON.parse(prev).message + finalChunk : finalChunk
                                    }));
                                    setMessageSend("");
                                };
                            };
                            readStream(); // 开始读取流
                            break;

                        // case "vision":
                        //     const dataVision = await response.json();
                        //     let messageVision;
                        //     let codeVision;

                        //     if(dataVision && dataVision.message) {
                        //         messageVision = dataVision.message.response;
                        //         codeVision = dataVision.message.code;
                        //     } else {
                        //         messageVision = dataVision.response;
                        //         codeVision = dataVision.code;
                        //     };                        

                        //     let jsonVision = {};

                        //     if(codeVision === 3000) {
                        //         jsonVision = JSON.stringify({ type: 'warning', message: messageVision });
                        //     }
                        //     else if(codeVision >= 4000) {       // 大于等于 4000 表示有错误
                        //         jsonVision = JSON.stringify({ type: 'error', message: messageVision });
                        //     } else {
                        //         jsonVision = JSON.stringify({ type: 'text', message: messageVision });                                                       
                        //     };

                        //     setMessageReceive(jsonVision);
                        //     setMessageSend("");
                        //     // 递增 forceUpdate 状态以强制触发 useEffect
                        //     setForceUpdate(prev => prev + 1);

                        //     break;

                        case "dalle":
                            const dataDalle = await response.json();
                            let codeDalle;
                            let messageDalle;

                            if(dataDalle && dataDalle.message) {
                                codeDalle = dataDalle.message.code;
                                messageDalle = dataDalle.message.response;
                            } else {
                                codeDalle = dataDalle.code;
                                messageDalle = dataDalle.response;
                            };

                            let jsonDalle = {};
                            
                            if(codeDalle === 3000) {
                                jsonDalle = JSON.stringify({ type: 'warning', message: messageDalle });
                            }
                            else if(codeDalle >= 4000) { // 大于等于 4000 表示有错误
                                jsonDalle = JSON.stringify({ type: 'error', message: messageDalle });
                            } else {
                                jsonDalle = JSON.stringify({ type: 'image', message: messageDalle });                                                       
                            };

                            setMessageReceive(jsonDalle);
                            setMessageSend("");
                            // 递增 forceUpdate 状态以强制触发 useEffect
                            setForceUpdate(prev => prev + 1);

                            break;

                        case "midjourney":
                        case "midjourney_graph":
                            const dataMidjourney = await response.json();
                            let codeMidjourney;
                            let messageMidjourney;
                            
                            if(dataMidjourney && dataMidjourney.message) {
                                codeMidjourney = dataMidjourney.message.code;
                                messageMidjourney = dataMidjourney.message.response;
                            } else {
                                codeMidjourney = dataMidjourney.code;
                                messageMidjourney = dataMidjourney.response;
                            };

                            let jsonMidjourney = {};

                            
                            if(codeMidjourney === 3000) {
                                jsonMidjourney = JSON.stringify({ type: 'warning', message: messageMidjourney });
                            }
                            else if(codeMidjourney >= 4000) {    // 大于等于 4000 表示有错误
                                jsonMidjourney = JSON.stringify({ type: 'error', message: messageMidjourney.message });
                            } else {
                                jsonMidjourney = JSON.stringify({ type: 'image', message: messageMidjourney });                                                       
                            };

                            setMessageReceive(jsonMidjourney);                        
                            setMessageSend("");
                            // 递增 forceUpdate 状态以强制触发 useEffect
                            setForceUpdate(prev => prev + 1);

                            break;

                        case "photomaker":
                        case "photomaker_admin":
                            const dataPhotoMaker = await response.json();
                            let codePhotoMaker;
                            let messagePhotoMaker;
                            
                            if(dataPhotoMaker && dataPhotoMaker.message) {
                                codePhotoMaker = dataPhotoMaker.message.code;
                                messagePhotoMaker = dataPhotoMaker.message;
                            } else {
                                codePhotoMaker = dataPhotoMaker.code;
                                messagePhotoMaker = dataPhotoMaker.response;
                            };
    
                            let jsonPhotoMaker = {};
                            
                            if(codePhotoMaker === 3000) {
                                jsonPhotoMaker = JSON.stringify({ type: 'warning', message: messagePhotoMaker });
                            }
                            else if(codePhotoMaker >= 4000) {    // 大于等于 4000 表示有错误
                                jsonPhotoMaker = JSON.stringify({ type: 'error', message: messagePhotoMaker.response ? messagePhotoMaker.response : messagePhotoMaker.message });
                            } else {
                                jsonPhotoMaker = JSON.stringify({ type: 'image', message: messagePhotoMaker });
                            };
    
                            setMessageReceive(jsonPhotoMaker);
                            setMessageSend("");
                            // 递增 forceUpdate 状态以强制触发 useEffect
                            setForceUpdate(prev => prev + 1);
    
                            break;

                        case "suno":                            
                            const readerSuno = response.body.getReader();
                            const decoderSuno = new TextDecoder('utf-8'); // 创建一个 TextDecoder 实例来解码流
                            const jsonSunoReload = JSON.stringify({ type: 'suno', reload: true });

                            let retryCountSuno = 1;
                            let cancelStreamSuno = false;
                            const maxRetriesSuno = 5; // 设置最大重试次数
                            let waitTimeSuno = 55 * 1000;

                            // 读取数据流
                            const readStreamSuno = async () => {                                
                                try{
                                    let done, value;
                                    while (true) { // 修改循环条件
                                        ({ done, value } = await readerSuno.read());
                                        if (done) {
                                            break;
                                        };
                                        setMessageReceive(jsonSunoReload);
                                        setMessageSend("");
                                        setForceUpdate(prev => prev + 1);

                                        const chunkSuno = decoderSuno.decode(value, { stream: true });
                                        const chunkSuno_obj = JSON.parse(chunkSuno);
                                        
                                        if (chunkSuno_obj.suno) {
                                            if (chunkSuno_obj.suno > 300) {
                                                toast.error(<div dangerouslySetInnerHTML={{ __html: chunkSuno_obj.message }} />);                                                
                                                break;
                                            } else if (chunkSuno_obj.suno > 200) {
                                                toast.info(<div dangerouslySetInnerHTML={{ __html: chunkSuno_obj.message }} />);                                                
                                                break;
                                            };
                                        };
                                    };

                                    // 如果流结束后还有数据没有被解码，确保将其添加到状态中
                                    const finalChunk = decoderSuno.decode();
                                    if (finalChunk) {
                                        // 创建 JSON 格式字符串并传递给 setMessageReceive
                                        // const jsonMessage = JSON.stringify({ type: messageTypeSuno, message: finalChunk });
                                        setMessageReceive(jsonSunoReload);
                                        setMessageSend("");
                                        setForceUpdate(prev => prev + 1);
                                    };
                                } catch (error) {
                                    // 在这里处理流读取错误
                                    setMessageReceive(jsonSunoReload);
                                    setMessageSend("");
                                    setForceUpdate(prev => prev + 1);
                                    waitTimeSuno -= 5000;
                                    
                                    if (retryCountSuno <= maxRetriesSuno && waitTimeSuno > 0) {
                                        if (!cancelStreamSuno) {
                                            try {
                                                await readerSuno.cancel();
                                                cancelStreamSuno = true;
                                            } catch (cancelError) {
                                                cancelStreamSuno = true;
                                            };
                                        }
                                        
                                        // 等待一段时间后重新连接
                                        setTimeout(() => {
                                            cancelStreamSuno = false;
                                            retryCountSuno++;
                                            // 重新调用 readStreamSuno 并确保错误被捕获
                                            readStreamSuno().catch(err => {
                                                // 处理重新调用时可能发生的错误
                                                // console.error('Error during reconnection attempt:', err);
                                                // 如果需要，可以在这里添加逻辑来停止重试或通知用户
                                            });
                                        }, waitTimeSuno); // 等待5秒
                                    } else {
                                        // console.warn(437, retryCountSuno);
                                    };
                                };
                            };
                            readStreamSuno(); // 开始读取流
                            break;

                        case "translator":                            
                            // const dataTranslator = await response.json();
                            // let messageTranslator;
                            // let codeTranslator;

                            // console.log(479, dataTranslator);

                            // if(dataTranslator && dataTranslator.message) {
                            //     messageTranslator = dataTranslator.message.response;
                            //     codeTranslator = dataTranslator.message.code;
                            // } else {
                            //     messageTranslator = dataTranslator.response;
                            //     codeTranslator = dataTranslator.code;
                            // };                        

                            // let jsonTranslator = {};

                            // if(codeTranslator === 3000) {
                            //     jsonTranslator = JSON.stringify({ type: 'warning', message: messageTranslator });
                            // }
                            // else if(codeTranslator >= 4000) {       // 大于等于 4000 表示有错误
                            //     jsonTranslator = JSON.stringify({ type: 'error', message: messageTranslator });
                            // } else {
                            //     jsonTranslator = JSON.stringify({ type: 'text', message: messageTranslator });                                                       
                            // };
                            // console.log(499, jsonTranslator);
                            // setMessageReceive(jsonTranslator);
                            // setMessageSend("");
                            // // 递增 forceUpdate 状态以强制触发 useEffect
                            // setForceUpdate(prev => prev + 1);

                            // break;
                            const readerTranslator = response.body.getReader();
                            const decoderTranslator = new TextDecoder('utf-8'); // 创建一个 TextDecoder 实例来解码流
                            const jsonTranslatorReload = JSON.stringify({ type: 'translator', reload: true });

                            let retryCountTranslator = 1;
                            let cancelStreamTranslator = false;
                            const maxRetriesTranslator = 5; // 设置最大重试次数
                            let waitTimeTranslator = 55 * 1000;

                            // 读取数据流
                            const readStreamTranslator = async () => {                                
                                try{
                                    let done, value;
                                    while (true) { // 修改循环条件
                                        ({ done, value } = await readerTranslator.read());
                                        if (done) {
                                            break;
                                        };
                                        
                                        const chunkTranslator = decoderTranslator.decode(value, { stream: true });
                                        const chunkTranslator_obj = JSON.parse(chunkTranslator);
                                        setMessageReceive(jsonTranslatorReload);
                                        setMessageSend("");
                                        setForceUpdate(prev => prev + 1);
                                        
                                        if (chunkTranslator_obj.translator) {                                            
                                            if (chunkTranslator_obj.translator === 3000) {
                                                toast.warn(<div dangerouslySetInnerHTML={{ __html: chunkTranslator_obj.message }} />);
                                                break;
                                            } else if (chunkTranslator_obj.translator > 300) {
                                                toast.error(<div dangerouslySetInnerHTML={{ __html: chunkTranslator_obj.message }} />);
                                                break;
                                            } else if (chunkTranslator_obj.translator > 200) {
                                                toast.info(<div dangerouslySetInnerHTML={{ __html: chunkTranslator_obj.message }} />);
                                                break;
                                            };
                                        } else if (chunkTranslator_obj.code === 200) {
                                            const toastTranslator = "Your document is processed.<br />您的文档已处理完成.";
                                            toast.success(<div dangerouslySetInnerHTML={{ __html: toastTranslator }} />);
                                        };
                                    };

                                    // 如果流结束后还有数据没有被解码，确保将其添加到状态中
                                    const finalChunk = decoderTranslator.decode();
                                    if (finalChunk) {
                                        // 创建 JSON 格式字符串并传递给 setMessageReceive
                                        // const jsonMessage = JSON.stringify({ type: messageTypeTranslator, message: finalChunk });
                                        setMessageReceive(jsonTranslatorReload);
                                        setMessageSend("");
                                        setForceUpdate(prev => prev + 1);
                                    };
                                } catch (error) {
                                    // 在这里处理流读取错误
                                    setMessageReceive(jsonTranslatorReload);
                                    setMessageSend("");
                                    setForceUpdate(prev => prev + 1);
                                    waitTimeTranslator -= 5000;
                                    
                                    if (retryCountTranslator <= maxRetriesTranslator && waitTimeTranslator > 0) {
                                        if (!cancelStreamTranslator) {
                                            try {
                                                await readerTranslator.cancel();
                                                cancelStreamTranslator = true;
                                            } catch (cancelError) {
                                                cancelStreamTranslator = true;
                                            };
                                        }
                                        
                                        // 等待一段时间后重新连接
                                        setTimeout(() => {
                                            cancelStreamTranslator = false;
                                            retryCountTranslator++;
                                            // 重新调用 readStreamTranslator 并确保错误被捕获
                                            readStreamTranslator().catch(err => {
                                                // 处理重新调用时可能发生的错误
                                                // console.error('Error during reconnection attempt:', err);
                                                // 如果需要，可以在这里添加逻辑来停止重试或通知用户
                                            });
                                        }, waitTimeTranslator); // 等待5秒
                                    } else {
                                        // console.warn(437, retryCountTranslator);
                                    };
                                };
                            };
                            readStreamTranslator(); // 开始读取流
                            break;

                        default :
                            break;
                    };
                } else {
                    setAttachments(null);
                    let jsonResponse = {};
                    if (!chatMedia.includes(messageObject.chatType)) {
                        switch (response.status) {
                            case 504 :
                                jsonResponse = JSON.stringify({ type: 'error', message: "Time out, please try again." });
                                setMessageReceive(jsonResponse);
                                setMessageSend("");
                                // 递增 forceUpdate 状态以强制触发 useEffect
                                setForceUpdate(prev => prev + 1);
                                break;
                            default:
                                break;
                        };
                    };
                };
            } catch (error) {
                setAttachments(null);
                console.error("Error to Chat:", error);
            };
        } else {
            setMessageSend("");
            setMessageReceive("");
            setIsReceived(true);
            setAttachments(null);
        };
    };

    const sendMessageToImage = async (
        imageObject, uid, model, classID, msgType, message
    ) => {
        try {
            let pre_index = null;
            let pre_operate = null;

            let version = "v";
            if (imageObject.version) {
                version = imageObject.version;
            } else {
                version = "v";
            };
            
            if(imageObject.pre_index) {
                pre_index = imageObject.pre_index;
                pre_operate = imageObject.pre_operate;
            } else if (imageObject.operate.toLowerCase() === "pan") {
                pre_index = imageObject.index;
                pre_operate = imageObject.operate.toLowerCase();
            };
            
            const response = await fetch(baseUrl_api + "/sendimageoperate", {
                method: "POST",
                headers: { "Content-Type": "application/json", },
                credentials: "include",
                body: JSON.stringify({
                    "uid": uid,
                    "model": model,
                    "classID": classID,
                    "chatType": imageObject.operate,
                    "msgType": msgType,
                    "channelid": imageObject.channelid, 
                    "image_url": imageObject.image_url,
                    "index": imageObject.index,
                    "isSquare": imageObject.isSquare,
                    "messageHash": imageObject.messageHash,
                    "messageid": imageObject.messageid,
                    "prompt": imageObject.prompt,
                    "message": message,
                    "pre_operate": pre_operate,
                    "pre_index": pre_index,
                    "version": version,
                    "log": imageObject.log,
                }),
            });

            if(response.status) {
                setIsReceived(true);
            };

            if(response.status === 200) {
                switch (model) {
                    case "midjourney":
                        const dataMidjourney = await response.json();

                        let codeMidjourney;
                        let messageMidjourney;
                        
                        if(dataMidjourney && dataMidjourney.message) {
                            codeMidjourney = dataMidjourney.message.code;
                            messageMidjourney = dataMidjourney.message.response;
                        } else {
                            codeMidjourney = dataMidjourney.code;
                            messageMidjourney = dataMidjourney.response;
                        };

                        let jsonMidjourney = {};
                        
                        if(codeMidjourney === 3000) {
                            jsonMidjourney = JSON.stringify({ type: 'warning', message: messageMidjourney });
                        }
                        else if(codeMidjourney >= 4000) {    // 大于等于 4000 表示有错误
                            jsonMidjourney = JSON.stringify({ type: 'error', message: messageMidjourney.message });
                        } else {
                            jsonMidjourney = JSON.stringify({ type: 'image', message: messageMidjourney });
                        };

                        setMessageReceive(jsonMidjourney);
                        // 递增 forceUpdate 状态以强制触发 useEffect
                        setForceUpdate(prev => prev + 1);
                        
                        setMessageSend("");

                        break;

                    default :
                        break;
                };
            } else {
                let jsonResponse = {};
                switch (response.status) {
                    case 504 :
                        jsonResponse = JSON.stringify({ type: 'error', message: "Time out, please try again." });
                        setMessageReceive(jsonResponse);// 递增 forceUpdate 状态以强制触发 useEffect
                        setForceUpdate(prev => prev + 1);
                        setMessageSend("");
                        break;
                    default:
                        break;
                };
            };

        } catch (error) {
            console.error("Error to Image:", error);
        };
    };

    // 定义一个函数来处理消息发送逻辑
    const handleSendMessage = async (messageObject) => {
        // console.log(354, !chatClass.className.toLowerCase().startsWith("photomaker"), messageObject, messageObject.text.code);
        let trimmedText;
        if (messageObject.text.code) {
            switch (messageObject.text.code.toLowerCase()) {
                case "chat":
                case "photomaker":
                case "photomaker_admin":
                case "vision":
                case "suno":
                case "translator":
                    // 如果是 vision 模块判断传入的 messageObject 是否含有 files
                    if (!('files' in messageObject.text)) {
                        messageObject.text.files = files;
                    };

                    trimmedText = messageObject.text.message.trim();
                    // 更新状态以保存发送的消息内容
                    setMessageSend(trimmedText);
                    setMessageReceive("");
                    // 递增 forceUpdate 状态以强制触发 useEffect
                    setForceUpdate(prev => prev + 1);
                    setIsReceived(false);
                    
                    // 如果需要传递文件地址, 添加地址, 其他类型不用
                    if (chatClassFile.includes(chatClass.className)) {
                        const textFiles = messageObject.text.files;
                        
                        if (textFiles && textFiles.length > 0) {
                            sendMessageToChat(messageObject, trimmedText);
                        } else {
                            setMessageSend("");
                            const noFileMessage = {
                                "type": "text",
                                "message": "You have not uploaded any file yet. Please upload first."
                            };
                            setMessageSend("");
                            setMessageReceive(noFileMessage);
                            setIsReceived(true);
                            // 递增 forceUpdate 状态以强制触发 useEffect
                            setForceUpdate(prev => prev + 1);
                        };
                    } else {
                        sendMessageToChat(messageObject, trimmedText);
                    };

                    break;
                case "midjourney":
                case "midjourney_graph":
                    // 如果是 midjorney 模块判断传入的 messageObject 是否含有 files
                    if (!('files' in messageObject.text)) {
                        messageObject.text.files = files;
                    };

                    trimmedText = messageObject.text.message.trim();
                    
                    // 更新状态以保存发送的消息内容
                    setMessageSend(trimmedText);
                    setMessageReceive("");
                    // 递增 forceUpdate 状态以强制触发 useEffect
                    setForceUpdate(prev => prev + 1);
                    setIsReceived(false);

                    // 如果需要传递文件地址, 添加地址, 其他类型不用
                    if (chatClassFile.includes(messageObject.text.code)) {
                        const textFiles = messageObject.text.files;
                        if (textFiles && textFiles.length > 0) {
                            sendMessageToChat(messageObject, trimmedText);
                        } else {
                            setMessageSend("");
                            const noFileMessage = {
                                "type": "text",
                                "message": "You have not uploaded any file yet. Please upload first."
                            };
                            setMessageSend("");
                            setMessageReceive(noFileMessage);
                            setIsReceived(true);
                            // 递增 forceUpdate 状态以强制触发 useEffect
                            setForceUpdate(prev => prev + 1);
                        };
                    } else {
                        sendMessageToChat(messageObject, trimmedText);
                    };

                    break;            
                case "midjourneyoperate":
                    setMessageSend( messageObject.text.describe );
                    setMessageReceive("");
                    setIsReceived(false);
                    // 递增 forceUpdate 状态以强制触发 useEffect
                    setForceUpdate(prev => prev + 1);
                    sendMessageToImage(
                        messageObject.text.message.image,
                        uid, messageObject.model, messageObject.classID,
                        messageObject.msgType, messageObject.text.describe
                    );

                    break;
                default:
                    break;
            };
        } else if (!chatClass.className.toLowerCase().startsWith("photomaker")) {            
            setMessageSend("");
            setMessageReceive("");
            setIsReceived(true);
        };
    };

    // 处理拖曳文件上传
    const handleFileUpload = async (dragFiles, isUpload = false) => {
        if (typeof dragFiles === "string") { 
            dragFiles = JSON.parse(dragFiles);
        };
        
        if (typeof dragFiles === "object") {
            if(dragFiles.length > 0) {
                let file;
                if(typeof dragFiles[0] === "string") {
                    file = JSON.parse(dragFiles[0]);
                } else {
                    file = dragFiles[0];
                };
                
                const type = file.filetype;
                if (isUpload) {
                    if (fileTranslator.includes(chatClass.className)) {
                        setShowFilesTranslate(true);
                        setShowFilesSend(false);
                    } else {
                        setShowFilesSend(true);
                        setShowFilesTranslate(false);
                    };
                    setShowFilesShow(false);
                } else {
                    setToolButtons(type); // 更新状态
                    setShowFilesSend(false);
                    setShowFilesShow(true);
                };
                
                setFileType(type);
                setFiles(dragFiles);
            } else {
                setShowFilesSend(false);
                setShowFilesShow(false);
                setFileType(null);
                setFiles([]);
            };
        };
    };

    // 处理对话框文件上传
    const handleOnFileLoadDone = (text) => {
        const textFiles = text.files;
        if (typeof textFiles === "object") {
            if(textFiles.length > 0) {
                let file;
                let type;
                
                if (typeof textFiles[0] === "object") {
                    file = textFiles[0];
                    type = file.filetype;
                } else {
                    file = JSON.parse(textFiles[0]);
                    type = file.filetype;
                };
                
                if (fileTranslator.includes(chatClass.className)) {
                    setShowFilesTranslate(true);
                    setShowFilesSend(false);
                } else {
                    setShowFilesSend(true);
                    setShowFilesTranslate(false);
                };
                setFileType(type);
                setFiles(textFiles);
            } else {
                setShowFilesSend(false);
                setFileType(null);
                setFiles([]);
            };
        };
    };

    
    const onSetParam = (param) => {
        switch (chatClass.className.toLowerCase()) {
            case "suno":
                setSunoParam(param.suno);
                break;
            default:
                break;
        }
    }

    const setFileSendMessage = (messageObject) =>{
        messageObject.classID = chatClass.classID;
        
        handleSendMessage(messageObject);        
    };

    const handleMJSetting = ((value) => {
        setMJParam(value);
        setImageParam(value);
    });

    const handlePMSetting = ((value) => {
        // console.log(603, value);
        setPMParam(value);        
        setImageParam(value);
    });

 
    return (
        <>
            { chatClass.className && (
                <>
                    <ChatMainMessage
                        uid={uid}
                        chatClass={chatClass}
                        sendMessage={messageSend}
                        receiveMessage={messageReceive}
                        forceUpdate={forceUpdate}
                        setImageOperate={handleSendMessage}
                        setFileUpload={handleFileUpload}
                        attachments={attachments}
                    />
                    
                    <ChatMainDialog
                        uid={uid}
                        chatClass={chatClass}
                        setSendMessage={handleSendMessage}
                        isReceived={isReceived}
                        onFileLoadDone={handleOnFileLoadDone}
                        toolButtons={toolButtons}
                        onShowFiles={() => setShowFilesShow(true)}
                        // onShowMJParam={() => setShowMJParam(true)}                        
                        onShowMJParam={handleShowParam}
                        onShowExpand={handleShowExpand}
                        onSetParam={onSetParam}
                    />
                </>
            )}

            { showFilesSend && (
                <FilesContainerSend
                    show={showFilesSend}
                    onHide={() => setShowFilesSend(false)}
                    files={files}
                    fileType={fileType}
                    setSendMessage={setFileSendMessage}
                    chatClassName={chatClass.className}
                    price={price}
                />
            )}

            { showFilesShow && (
                <FilesContainerShow
                    show={showFilesShow}
                    onHide={() => setShowFilesShow(false)}
                    files={files}
                    fileType={fileType}
                    price={price}
                />
            )}

            { showFilesTranslate && (
                <FilesContainerTranslate
                    show={showFilesTranslate}
                    onHide={() => setShowFilesTranslate(false)}
                    files={files}
                    fileType={fileType}
                    setSendMessage={setFileSendMessage}
                    chatClassName={chatClass.className}
                    price={price}
                />
            )}

            { showMJParam && (
                <ShowMJParam
                    show={showMJParam}
                    onHide={() => setShowMJParam(false)}
                    onSetting={handleMJSetting}
                    param={mjParam}
                />
            )}

            { showPMParam && (
                <ShowPMParam
                    show={showPMParam}
                    onHide={() => setShowPMParam(false)}
                    onSetting={handlePMSetting}
                    param={pmParam}
                />
            )}

            { showMJImageEXpand && (
                < MJImageExpand
                    uid={uid}
                    show={showMJImageEXpand}
                    onHide={() => setShowImageExpand(false)}
                    // files={files}
                    // fileType={fileType}
                    setSendMessage={setFileSendMessage}
                    chatClassName={chatClass.className}
                    price={price}
                />
            )}

            { showSunoExpand && (
                < SunoExpand
                    uid={uid}
                    show={showSunoExpand}
                    onHide={() => setShowSunoExpand(false)}
                    setSendMessage={setFileSendMessage}
                    chatClassName={chatClass.className}
                />
            )}
        </>
    );
};

export default ChatMain;
